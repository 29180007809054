<template>
  <div class="bg-white padding-main">
    <div class="container-fluid">
      <div class="row head-row-cons">
        <div class="col-md-6">
          <div class="conversation-title text-left">
            <h3><img src="../../../public/img/conversation-trans.png" alt="Conversations"/> Conversations</h3>
          </div>
        </div>
        <div class="col-md-6">
          <div class="conversation-right-icon text-right">
            <div class="msg-icon">
              <a href="#">
                <img src="../../../public/img/email.png" alt=""/>
              </a>
              <a href="#" class="chats-icon">
                <img src="../../../public/img/sms.png" alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row main-conversations">
        <div class="col-2xl-4    col-md-5 col-sm-12">
          <div class="left-bar-chat" id="chatNoti">
            <!-- Number -->
            <div class="sms-number">
              <h3><img src="../../../public/img/blue-chat.png"/> SMS</h3>
              <h5 style="color: #f16925;">{{ unread_messages }}</h5>
            </div>
            <div class="left-notify-scroll-bar">
              <div class="left-notify-chat-wrap">
                <!-- ITEMS-NOTIFY -->
                <div v-for="participant in participants" :key="participant['id']"
                     :class="'items-notify '+ (participant['notify']? '' : 'un-seen-msg')"
                     @click="mount_conversations(participant)">
                  <div class="title-name-head">
                    <h4>{{ participant['title'] }}</h4>
                    <div class="time-notify-chat">
                      <p class="flex-time"><img src="../../../public/img/timestamp.png"/>{{
                          datetime_format(participant['messages'][0].created_at)
                        }}</p>
                    </div>
                  </div>
                  <div class="notify-short-msg">
                    <p>{{ message_shorten(participant['messages'][0] ? participant['messages'][0].text : '') }}</p>
                  </div>
                </div>
                <infinite-loading :identifier="pagination.participant.identifier" direction="down"
                                  @infinite="infinite_participant" spinner="waveDots">
                  <div slot="no-more"></div>
                  <div slot="no-results"></div>
                </infinite-loading>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2xl-8  col-md-7 col-sm-12 fd">
          <div class="right-bar-conversation" id="conversationHole">
            <div class="reverse-head">
              <h4>{{ getname(pagination.conversation) }}</h4>
              <a href="#"> <img src="../../../public/img/blue-bothways.png"/> </a>
              <h4 class="clr-up">{{ user.name }}</h4>
              <div class="cross-icon-chat" id="iconChatCross" v-on:click="crossFun()">
                <img src="../../../public/img/left-icon.png">
              </div>
            </div>
            <div class="conversation-wrap-right">
              <div class="conversation-scroll-bar" id="conversation_body">
                <div class="conversation-wrap-inner">

                  <infinite-loading :identifier="pagination.conversation.identifier" direction="top"
                                    @infinite="infinite_conversation" spinner="waveDots">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                  </infinite-loading>
                  <div
                    :class="conversation['direction'] === 'outbound'
                    || conversation['notes'] !== undefined ? 'sender-msges' : 'recivers-msges'"
                    v-for="conversation in conversations" :key="conversation['id']">
                    <div class="conversa-date" v-if="show_date(conversation)">
                      <p>{{ date_format(conversation['created_at']) }}</p>
                      <span class="shap-date"></span>
                    </div>
                    <div v-if="conversation['text'] !== undefined && conversation['text'] !== ''" class="inner-sender">
                      <p>
                        {{ conversation['text'] }}
                      </p>
                    </div>
                    <div v-if="conversation['notes'] !== undefined && conversation['notes'] !== ''" class="inner-sender"
                         style="background-color:rgb(66 66 66);">
                      <p>
                        {{ conversation['notes'] }}
                      </p>
                    </div>
                    <div v-if="conversation['text'] === undefined && conversation['notes'] === undefined"
                         class="inner-sender">
                                    <span>
                                      <p v-if="conversation['record_url'] === ''">
                                        The call may be ended before it started.
                                      </p>
                                      <hr v-if="conversation['text'] === undefined"/>
                                      <div class="conversation_footer"
                                           v-if="conversation['text'] === undefined && conversation['notes'] === undefined">
                                        <vue-audio :file="conversation['record_url']"></vue-audio>
                                      </div>
                                    </span>
                    </div>
                    <p class="flex-time"><img src="../../../public/img/timestamp.png"/>
                      {{ time_format(conversation['created_at']) }}</p>
                  </div>
                </div>
              </div>
              <div class="send-typing-msg-wrap">
                <div class="input-send">
                  <div class="from-group">
                    <input type="text" id="message_text" v-on:keyup.enter="send" placeholder="Type here ...">
                  </div>
                  <div class="btn-send-icon" @click="send">
                    <a href="#"> <img src="../../../public/img/sms-send.png"> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require('moment');
import VueAudio from "../../components/leads/VueAudio";

export default {
  components: {
    VueAudio
  },
  data() {
    return {
      unread_messages: '...',
      participants: [],
      conversations: [],
      pagination: {
        conversation: {
          page: 0,
          participant: {},
          identifier: new Date()
        },
        participant: {
          page: 0,
          search: '',
          identifier: new Date()
        }
      },
      channel: {
        sms: {},
        call: {}
      },
      user: this.$store.getters['api/user'],
    }
  },
  created() {
    this.$store.dispatch('conversation/unread_messages').then(response => {
      this.unread_messages = response.data.data;
    });
  },
  methods: {
    init() {
      this.channel.sms = this.$pusher.subscribe('MessageEvent');
      this.channel.sms.bind('received', this.sms_event);

      this.channel.call = this.$pusher.subscribe('CallStatus');
      this.channel.call.bind('recorded', this.call_record_event);
    },
    call_record_event(data) {
      //console.log({RECORD : data});
      if (data.call.id !== undefined) {
        if (this.pagination.conversation.participant['id'] !== undefined) {
          var participant = this.pagination.conversation.participant;
          //console.log(participant);
          if (participant['id'] === data.call.parent_id && data.call.parent_type.toString().includes(participant['type'])) {
            this.conversations.push(data.call);
            setTimeout(function () {
              var objDiv = document.getElementById("conversation_body");
              objDiv.scrollTop = objDiv.scrollHeight;
            }, 500);
          }
        }
      }
    },
    sms_event(data) {
      //console.log({ SMS_EVENT : data });
      if (data.object !== undefined) {
        if (this.pagination.conversation.participant['id'] !== undefined) {
          var participant = this.pagination.conversation.participant;
          //console.log({SMS_PARTICIPANT : participant});
          if (participant.id === data.object.id && data.object.parent_type === participant['type'].toString().toLowerCase()) {
            this.conversations.push(data.message);
            setTimeout(function () {
              var objDiv = document.getElementById("conversation_body");
              objDiv.scrollTop = objDiv.scrollHeight;
            }, 500);
            this.$store.dispatch(data.object.parent_type.toString().toLowerCase() + "/mark", data.object.id).then((response) => {
              var index = this.participants.findIndex(p => (p.id === participant['id'] && p.type === participant['type']));
              //console.log({MARK_REMOVE:this.participants[index]});
              if (index !== undefined) {
                this.participants[index]['notify'] = false;
              }
            }).catch((response) => {

            });
          } else {
            for (var x = 0; x < this.participants.length; x++) {
              var match = false;
              if (this.participants[x].id === data.object.id && this.participants[x].type.toString().toLowerCase() === data.object.parent_type) {
                match = true;
                this.participants[x]['notify'] = true;
              }
            }
          }
        }
      }
    },
    infinite_participant($state) {
      var payload = {};
      this.pagination.participant.page = this.pagination.participant.page + 1;
      payload.page = this.pagination.participant.page;
      if (this.pagination.participant.search !== '') {
        payload.search = this.pagination.participant.search;
      }
      this.$store
        .dispatch("conversation/participants", payload)
        .then(response => {
          this.$insProgress.finish();
          //console.log(response);
          var participants = response.data.data;
          var previous_length = this.participants.length;
          this.participants.push(...participants);
          if (this.participants.length === previous_length) {
            $state.complete();
          } else {
            $state.loaded();
            // if(this.$route.params.id !== undefined){
            //   this.mount_conversations(this.participants[0]);
            // }
          }
        })
        .catch(error => {

        });
    },
    show_date(conversation) {
      let conversation_dates = this.conversations.map((conversation) => {
        return {
          id: conversation['id'],
          date: this.date_format(conversation['created_at']),
        };
      });

      let date = this.date_format(conversation['created_at']);

      let index = conversation_dates.findIndex(function (element) {
        return element.date === date;
      });

      if (conversation_dates[index].id === conversation['id']) {
        return true;
      } else {
        return false;
      }
    },
    search_participants(page = false) {
      this.$insProgress.start();
      this.pagination.participant.page = 0;
      this.participants = [];
      this.pagination.participant.identifier = new Date();
    },
    infinite_conversation($state) {
      this.pagination.conversation.page = this.pagination.conversation.page + 1;
      var data = {};
      if (this.pagination.conversation.participant['id'] !== undefined) {
        var participant = this.pagination.conversation.participant;
        data.parent_id = this.pagination.conversation.participant['id'];
        data.parent_type = this.pagination.conversation.participant['type'].toString().toLowerCase();
        data.page = this.pagination.conversation.page;
        this.$store.dispatch("conversation/list", data).then((response) => {
          this.$insProgress.finish();

          this.$store.dispatch(data.parent_type + "/mark", data.parent_id).then((response) => {
            var index = this.participants.findIndex(p => (p.id === participant['id'] && p.type === participant['type']));
            //console.log({MARK_REMOVE:this.participants[index]});
            if (index !== undefined) {
              this.participants[index]['notify'] = false;
            }
          }).catch((response) => {

          });
          var previous_length = this.conversations.length;
          this.conversations.unshift(...response.data.data.reverse());
          if (this.conversations.length === previous_length) {
            $state.complete();
          } else {
            $state.loaded();
          }
          //console.log(this.conversations);
        }).catch((error) => {
          this.$insProgress.finish();
        });
      } else {
        $state.complete();
      }
    },
    mount_conversations(participant) {
      this.participants.forEach((element, index) => {
        if (this.$route.params.id !== undefined && this.route_load === false) {
          if (element['id'] === this.$route.params.id) {
            this.participants[index]['select'] = true;
            this.route_load = true;
          }
        } else {
          if (element['id'] === participant['id']) {
            this.participants[index]['select'] = true;
          } else {
            this.participants[index]['select'] = false;
          }
        }
      });
      //console.log(participant);
      this.conversations = [];
      this.pagination.conversation.page = 0;
      this.pagination.conversation.participant = participant;
      this.$insProgress.start();
      this.pagination.conversation.identifier = new Date();
      this.conFun();
    },
    send() {
      let participant = this.pagination.conversation.participant;
      var message = document.getElementById('message_text').value;
      if (message !== '' && message !== undefined) {
        document.getElementById('message_text').value = "";
        this.conversations.push({
          read: 1,
          status: 'queued',
          type: 'message',
          text: message,
          direction: 'outbound',
          updated_at: Date.now(),
          created_at: Date.now()
        });
        setTimeout(function () {
          var objDiv = document.getElementById("conversation_body");
          objDiv.scrollTop = objDiv.scrollHeight;
        }, 500);
        this.$store.dispatch(participant['type'].toString().toLowerCase() + "/conversation_send", {
          id: participant['id'],
          message: message
        }).then((response) => {
          //console.log(response);
        });
      }
    },
    getname(conversation) {
      if (conversation) {
        if (conversation.participant.title) {
          return conversation.participant.title;
        }
      }
      return '';
    },
    datetime_format(date_string) {
      return moment(date_string).format('MMMM Do YYYY, h:mm:ss a');
    },
    date_format(date_string) {
      return moment(date_string).format('MMMM Do YYYY');
    },
    time_format(date_string) {
      return moment(date_string).format('h:mm a');
    },
    message_shorten(message, limit = 63) {
      if (message.length > limit) {
        return message.substring(0, limit) + '...';
      } else {
        return message;
      }
    },
    conFun() {
      var element = document.getElementById("conversationHole");
      element.classList.add("classone");
      var element = document.getElementById("chatNoti");
      element.classList.add("on-none");
    },
    crossFun() {
      var element = document.getElementById("conversationHole");
      element.classList.remove("classone");
      var element = document.getElementById("chatNoti");
      element.classList.remove("on-none");
    }
  }
};
</script>


<style scoped>

.row.head-row-cons {
  padding: 24px 0 30px;
}

.conversation-title h3 {
  color: #f16925;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-top: 6px;
}

span.shap-date {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f3f3f3;
  display: flex;
  margin: 0 auto;
}

.conversation-title h3 img {
  max-width: 28px;
  margin-right: 8px;
}

.msg-icon a {
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px 0 0;
  border-radius: 100%;
  padding: 12px;
  border: 1px solid #ebebeb;
}

.msg-icon {
  display: flex;
  justify-content: end;
}

.msg-icon a.chats-icon {
  background: #f16925;
}

.msg-icon a img {
  max-width: 24px;
}

.sms-number {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #eeecec;
  max-width: 220px;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.sms-number h3,
.sms-number h5 {
  margin-bottom: 0;
}

.sms-number h3 {
  font-size: 18px;
  color: #2d5b87;
  font-weight: 500;
}

.sms-number h3 img {
  margin-right: 6px;
}

.sms-number h5 {
  background: #fbeded;
  padding: 2px 8px;
  border-radius: 6px;
  color: #2d5b87;
}

.left-notify-chat-wrap {
  background: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
}

.items-notify {
  border-bottom: 1px solid #e7e4e4;
  padding: 18px;
}

.title-name-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.title-name-head h4 {
  margin-bottom: 0;
}

.title-name-head h4 {
  margin-bottom: 0;
  color: #f16925;
  font-weight: 500;
  font-size: 16px;
}

.clr-up {
  color: #f16925 !important;
}

.title-name-head p.flex-time {
  font-size: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: #bab7b7;
}

.title-name-head p.flex-time img {
  max-width: 12px;
  margin-right: 4px;
  margin-top: -1px;
}

.notify-short-msg p {
  margin-bottom: 0;
  font-size: 13px;
  line-height: 14px;
  color: #7d7d7d;
}

.reverse-head {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  padding: 6px 0 25px;
}

.reverse-head h4 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
  color: #2d5b87;
}

.reverse-head a {
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reverse-head a img {
  max-width: 18px;
}

.row.main-conversations {
  padding-bottom: 40px;
}

.conversation-wrap-right {
  background: #fff;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
}

.conversation-wrap-inner {
  padding: 20px 20px 20px;
}

.conversa-date p {
  background: #f3f3f3;
  font-size: 12px;
  padding: 4px 10px 6px;
  color: #c1bbbb;
  border-radius: 10px;
  max-width: 140px;
  text-align: center;
  margin: 0 auto;
}

.conversa-date {
  margin-bottom: 18px;
}

.sender-msges .inner-sender {
  background: #ee804a;
  display: inline-block;
  padding: 6px 14px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  text-align: left;
  margin-bottom: 6px;
}

.sender-msges {
  text-align: end;
  margin-bottom: 18px;
}

.sender-msges .inner-sender p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.sender-msges p.flex-time {
  font-size: 10px;
  margin-bottom: 0;
  color: #bab7b7;
}

.sender-msges p.flex-time img {
  max-width: 12px;
  margin-right: 4px;
  margin-top: -1px;
}

.recivers-msges .inner-sender {
  background: #2c669d;
  display: inline-block;
  padding: 6px 14px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: left;
  margin-bottom: 6px;
}

.recivers-msges .inner-sender p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
}

.recivers-msges p.flex-time {
  font-size: 10px;
  margin-bottom: 0;
  color: #bab7b7;
}

.recivers-msges p.flex-time img {
  max-width: 12px;
  margin-right: 4px;
  margin-top: -1px;
}

.recivers-msges {
  margin-bottom: 18px;
}

.send-typing-msg-wrap {
  background: #f8f8fb;
  border-top: 1px solid #d9d9d9;
}

.input-send {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.input-send .from-group {
  width: 90%;
}

.un-seen-msg {
  background: #fcfcfc;
}

.un-seen-msg h4 {
  color: #2e5d89 !important;
}

.from-group input {
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  color: #245482;
}

.from-group input:focus-visible {
  outline-color: #f3f3f3 !important;
}

.btn-send-icon a {
  background: #8c8d97;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-notify-scroll-bar {
  overflow-y: auto;
  height: 72vh;
  padding-right: 20px;
}

.conversation-scroll-bar {
  overflow-y: auto;
  height: 64vh;
}

.left-notify-scroll-bar::-webkit-scrollbar, .conversation-scroll-bar::-webkit-scrollbar {
  width: 6px;
}

.left-notify-scroll-bar::-webkit-scrollbar-track, .conversation-scroll-bar::-webkit-scrollbar-track {
  background: #d3dde6;
  border-radius: 2px;
}

.left-notify-scroll-bar::-webkit-scrollbar-thumb, .conversation-scroll-bar::-webkit-scrollbar-thumb {
  background: #245482;
  border-radius: 2px;
}

.left-notify-scroll-bar::-webkit-scrollbar-thumb:hover, .conversation-scroll-bar::-webkit-scrollbar-thumb {
  background: #245482;
}

@media (min-width: 1800px) {
  .col-2xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-2xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

@media (max-width: 992px) {
  .right-bar-conversation {
    display: none;
  }

  .on-none {
    display: none;
  }

  .right-bar-conversation.classone {
    display: block;
  }

  .reverse-head {
    padding: 6px 0 25px;
    width: 100%;
    max-width: 100%;
    justify-content: end;
    position: relative;
  }

  .reverse-head a {
    margin: 0 10px;
  }

  .cross-icon-chat {
    position: absolute;
    left: 0;
    z-index: 999;
  }

  .main-conversations .col-sm-12 {
    max-width: 100% !IMPORTANT;
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  div#iconChatCross {
    display: none;
  }
}


</style>
